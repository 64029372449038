.typing-container {
    font-family: monospace;
    white-space: nowrap; /* Ensures text doesn't wrap */
    overflow: hidden; /* Keeps the container from expanding */
    border-right: 3px solid; /* Simulates the blinking cursor */
    width: 20ch; /* Adjust width as needed */
    animation: typing 4s steps(20) infinite, blink 0.5s infinite step-end alternate;
  }
  
  @keyframes typing {
    from { width: 0; }
    to { width: 20ch; }
  }
  
  @keyframes blink {
    50% { border-color: transparent; }
  }
  
  @media (max-width: 768px) {
    .typing-container {
      width: 10vw; /* Adjust width as needed */
    }
  }