@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --gradient-color: #231f4d;
}

body {
  background: linear-gradient(35deg, var(--gradient-color) 40%, #bf3694 100%);
  transition: background-color 0.3s ease, color 0.3s ease;
  /* backdrop-filter: blur(500%); */
  margin-top: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden; /* Hide horizontal scrollbar if it appears */
  overflow-y: scroll; /* Optional: use `hidden` if you don't want scrolling */
  scrollbar-width: none; /* For Firefox */
  scroll-behavior: smooth;
}

body.light {
  --gradient-color: #56CCF2; /* Light mode gradient start color */
}

.App {
  text-align: center;
  min-height: 100vh;
}


.frosted-navbar {
  position: fixed; /* or 'absolute' */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* High z-index to ensure it's above other content */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  transition: background-color 0.4s; /* Smooth transition for the background change */
}

.frosted-navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.2);
}

.navbar-nav .nav-link {
  color: white !important;
}


body::-webkit-scrollbar {
  display: none;
}

body.light::before {
  opacity: 0; /* Hide gradient for light mode */
}

body.dark::before {
  opacity: 1; /* Show gradient for dark mode */
}

.main-content {
  margin-top: 20vh;
  padding-bottom: 15vh;
}

#about {
  margin-top: 20vh;
  padding-bottom: 20vh;
}

#home {
  margin-top: -1vh;
  padding-bottom: 9vh;
}

.pics-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Aligns items to the start of the flex container */
  flex-wrap: wrap; /* Allows items to wrap as needed */
  margin-bottom: 20vh;
}

.about-content {
  width: 60%; 
  height: 800px;
  margin-bottom: 100px;
  padding: 50px;
  background: white; /* or any other color or gradient */
  border-radius: 10px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
  text-align: left;
  position: relative; /* Important for absolute positioning of images relative to this container */
  display: flex;
  flex-direction: column;
  justify-content: space-around; /* This will spread the children evenly */
}

.about-content h1 {
  color: #333; /* Title color */
  font-size: calc(1rem + 1vw); /* Dynamically adjusting font size */
  margin-bottom: 2vh; /* Responsive vertical spacing */
}

.about-content p {
  color: #666; /* Paragraph text color */
  font-size: calc(0.5rem + 0.5vw); /* Smaller base size with a slight increase based on the viewport width */
  line-height: 1.6; /* Line height for readability */
  margin-bottom: 2vh; /* Responsive paragraph spacing */
}

.biking-img {
  position: absolute;
  width: 17vw;
  height: auto;
  top: 142%;
  left: 81%; 
} 

.leaning-pic {
  position: absolute;
  width: 19vw;
  height: auto;
  top: 47%;  
  left: 1.3%; /* Adjust as needed for visual balance */
}

.db-pic {
  position: absolute;
  height: 17vh;
  width: auto;
  top: 76%; /* Positioned at the bottom of the about-content */
  left: 74%; /* Adjust this value to position it at the bottom right */
}

.cloud-pic {
  position: absolute;
  height: 15vh;
  width: auto;
  top: -7%; /* Positioned above the about-content */
  left: 15.6%; /* Positioned to the left of the about-content */
}

.navbar-profile-pic {
  height: 50px; /* Adjust size as needed */
  width: 50px; /* Adjust size as needed */
  margin-right: 20px;
  border-radius: 50%; /* Makes the image round */
  object-fit: cover; /* Ensures the image covers the area without distortion */
  border: 1px solid white; 
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.footer {
  z-index: 1000; /* High z-index to ensure it's above other content */
  background-color: rgba(255, 255, 255, 0); /* Adjust the color and transparency as needed */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px; /* Adjust padding as needed */
}

.footer a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
}

.footer i, .footer p {
  margin: 0; /* Removes default margin */
  color: white;
}

.web-dev-image {
  position: relative;
  float: right;
  margin-top: -16vh;
  margin-right: 8vw;
}

.left-align-text {
  text-align: left;
  margin-left: 15vw;
  margin-bottom: 35vh;
}

.left-align-text h1 {
  margin-top: 7.5vh;
  margin-bottom: 12.5vh;
}

.left-align-text h3 {
  margin-bottom: 4vh;
}

.im-a {
  text-align: left;
  display: block; /* Ensures it takes the full width */
}

.im-a, .typing-container {
  display: inline;
  font-family: monospace;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
 10% { transform: rotate(14.0deg) }  /* Slightly rotate clockwise */
 20% { transform: rotate(-8.0deg) }  /* Then rotate counter-clockwise */
 30% { transform: rotate(14.0deg) }
 40% { transform: rotate(-4.0deg) }
 50% { transform: rotate(10.0deg) }
 60% { transform: rotate( 0.0deg) }  /* Return to the original position */
 100% { transform: rotate( 0.0deg) }
}

.wave {
  display: inline-block;
  animation-name: wave-animation;
  animation-duration: 2.5s;      /* Duration of the animation */
  animation-iteration-count: infinite; /* Repeat the animation */
  transform-origin: 70% 70%; /* Pivot around the bottom-right of the emoji */
  margin-left: 5px;
}


.document-display iframe {
  width: 100%;
  height: 1000px; /* Adjust as needed */
  border: none;
}

.document-item {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items vertically */
  margin-top: 10vh; 
}

.document-display img {
  height: 650;
  width: 500px;
  margin-bottom: 1vh;
}

.button-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.document-display {
  max-width: 90%;
  margin: 0 auto;
}


github {
  margin-top: 20vh;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  place-items: center;
  gap: 3rem; /* Adjust the gap to your liking */
  padding: 1.5rem;
  margin: 0 auto;
  max-width: 95%;
}

.grid-item {
  width: 100%; /* Each item will fill the column */
  height: auto;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 10px;
  overflow: hidden; /* Ensures content fits within the borders */
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text */
  overflow: hidden;
  padding-bottom: 1rem;
}

.grid-item img {
  width: 100%; /* Image will fill the width of the item */
  height: 300px; /* Maintain aspect ratio */
  display: block;
  object-fit: cover;
  margin-bottom: 1rem;
}

.grid-item .p2p-img {
  width: 65%;
  height:auto;
}

.grid-item a {
  font-weight: bold;
  color: #000000; /* Adjust the color to match your theme */
  text-decoration: none;
}

.grid-item p {
  margin-top: 1rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.text-content {
  top: 220px;
  text-align: center; /* or right, if preferred */
  width: 100%; /* Ensures the container spans the width of the grid item */
  margin-top: 50px;
}

.grid-item a, .grid-item p {
  margin-left: 1rem; /* Adjust as needed to align with text content */
  margin-right: 1rem; /* Adjust as needed for right padding */
}

h1 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #ffffff; /* A light color for the text */
  /* #2c2c2c */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Soft shadow for depth */
  text-transform: uppercase; 
  letter-spacing: 1px; 
  font-size: 30px;
  font-weight: 400; 
  margin-bottom: 20px; /* Spacing below heading */
  -webkit-background-clip: text;
  background-clip: text;
  display: inline-block;
  margin-bottom: 10vh;
}

h2 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #2c2c2c; /* A light color for the text */
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  */
  text-transform: uppercase; 
  letter-spacing: 1px; 
  font-size: 24px;
  font-weight: 500; 
  margin-bottom: 20px; /* Spacing below heading */
  -webkit-background-clip: text;
  background-clip: text;
  display: inline-block;
}

h3 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #ffffff; /* A light color for the text */
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  */
  letter-spacing: 1px; 
  font-size: 24px;
  font-weight: 400; 
  margin-bottom: 20px; /* Spacing below heading */
  -webkit-background-clip: text;
  background-clip: text;
}

h4 {
  color: #ffffff; /* A light color for the text */
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  */
  letter-spacing: 1px; 
  font-size: 20px;
  font-weight: 400; 
  margin-bottom: 20px; /* Spacing below heading */
  -webkit-background-clip: text;
  background-clip: text;
}

/* Add media queries as needed for responsiveness */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .text-content {
    top: 100px;
  }

  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 14px;
  }

  p {
    font-size: 12px;
  }

  .switch-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3vh;
    margin-bottom: 3vh;
    margin-left: -3px;
  }

  .left-align-text{
    margin-left: 5%;
    margin-bottom: 55%;
  }

  .about-content-home {
    padding: 5%;
  }

  .about-content-home h2 {
    margin-top: 5%;
  }

  .about-content {
    padding: 5%;
  }

  .about-content h1 {
    margin-top: 5%;
  }

  .db-pic {
    top: 82%;
    left: 71%;
    width: 20vw;
    height:auto;
  }
  
  .cloud-pic {
    top: -4.5%;
    left: 10%;
    width: 20vw;
    height:auto;
  }

  .leaning-pic {
    position: absolute;
    top: 76%;
    left: 0%;
    width: 20.5vw;
    height:auto;
  }
  
  .web-dev-image {
    position: absolute; 
    top: 57%;
    left: 60%;
    width: 40vw;
    height:auto;
  }
  
  .biking-img {
    width: 32vw;
    height: auto;
    top: 162%;
    left: 64%;
  }

  .github-row {
    margin-left: 5%;
    max-width: 90%;
  }

  .footer{
    font-size: 10px;
  }

  .logos-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  }
}

/* The switch - the box around the slider */
.switch {
  display: block;
  --width-of-switch: 4em;
  --height-of-switch: 2.5em;
  /* size of sliding icon -- sun and moon */
  --size-of-icon: 1.4em;
  /* it is like a inline-padding of switch */
  --slider-offset: 0.3em;
  position: relative;
  width: var(--width-of-switch);
  height: var(--height-of-switch);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f4f5;
  transition: .4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: var(--size-of-icon,1.4em);
  width: var(--size-of-icon,1.4em);
  border-radius: 20px;
  left: var(--slider-offset,0.3em);
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(40deg,#ff0080,#ff8c00 70%);
  ;
 transition: .4s;
}

input:checked + .slider {
  background-color: #303136;
}

input:checked + .slider:before {
  left: calc(100% - (var(--size-of-icon,1.4em) + var(--slider-offset,0.3em)));
  background: #303136;
  /* change the value of second inset in box-shadow to change the angle and direction of the moon  */
  box-shadow: inset -3px -2px 5px -2px #8983f7, inset -10px -4px 0 0 #a3dafb;
}

ul {
  list-style: none;
}

.example-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.example-2 .icon-content {
  margin: 0 10px;
  position: relative;
}
.example-2 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}
.example-2 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}
.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: #4d4d4d;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}
.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 30px;
}
.example-2 .icon-content a:hover {
  color: white;
}
.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover .filled {
  height: 100%;
}

.example-2 .icon-content a[data-social="linkedin"] .filled,
.example-2 .icon-content a[data-social="linkedin"] ~ .tooltip {
  background-color: #0274b3;
}

.example-2 .icon-content a[data-social="github"] .filled,
.example-2 .icon-content a[data-social="github"] ~ .tooltip {
  background-color: #24262a;
}
.example-2 .icon-content a[data-social="instagram"] .filled,
.example-2 .icon-content a[data-social="instagram"] ~ .tooltip {
  background: linear-gradient(
    45deg,
    #405de6,
    #5b51db,
    #b33ab4,
    #c135b4,
    #e1306c,
    #fd1f1f
  );
}


*, *:before, *:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.screen {
  position: relative;
  margin: 0 auto;
  background: #3e3e3e;
  border-radius: 15px;
  width: 80%;
}

.screen:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
  z-index: -1;
}

.screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #4d4d4f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.screen-header-left {
  margin-right: auto;
}

.screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: white;
}

.screen-header-button.close {
  background: #ed1c6f;
}

.screen-header-button.maximize {
  background: #e8e925;
}

.screen-header-button.minimize {
  background: #74c54f;
}

.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #999;
}

.screen-body {
  display: flex;
}

.screen-body-item {
  flex: 1;
  padding: 50px;
}

.screen-body-item.left {
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.app-title {
  display: flex;
  flex-direction: left;
  position: relative;
  color: #ea1d6f;
  font-size: 26px;
}

.app-title:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 25px;
  height: 4px;
  background: #ea1d6f;
}

.app-contact {
  margin-top: auto;
  font-size: 8px;
  color: #888;
}

.app-form-group {
  margin-bottom: 15px;
}

.app-form-group.message {
  margin-top: 40px;
}

.app-form-group.buttons {
  margin-bottom: 0;
  text-align: right;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
}

.app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #666;
  color: #ddd;
  font-size: 14px;
  text-transform: uppercase;
  outline: none;
  transition: border-color .2s;
}

.app-form-control::placeholder {
  color: #666;
}

.app-form-control:focus {
  border-bottom-color: #ddd;
}

.app-form-button {
  background: none;
  border: none;
  color: #ea1d6f;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.app-form-button:hover {
  color: #b9134f;
}

.dribbble {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

@media screen and (max-width: 520px) {
  .screen-body {
    flex-direction: column;
  }

  .screen-body-item.left {
    margin-bottom: 30px;
  }

  .app-title {
    flex-direction: row;
  }

  .app-title span {
    margin-right: 12px;
  }

  .app-title:after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .screen-body {
    padding: 40px;
  }

  .screen-body-item {
    padding: 0;
  }
}

.custum-file-upload {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 100px;
  width: 250px;
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 2px dashed #848484;
  background-color: rgba(255, 255, 255, 0);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px rgba(0,0,0,0.1);
}

.custum-file-upload .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .icon svg {
  height: 40px;
  fill: rgba(75, 85, 99, 1);
}

.custum-file-upload .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .text span {
  font-weight: 400;
  color: rgba(75, 85, 99, 1);
}

.custum-file-upload input {
  display: none;
}

.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: .3s;
  overflow: hidden;
  position: relative;
}

.svgIcon {
  width: 12px;
  transition-duration: .3s;
}

.svgIcon path {
  fill: white;
}

.button:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: .3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
}

.button:hover .svgIcon {
  width: 50px;
  transition-duration: .3s;
  transform: translateY(60%);
}

.button::before {
  position: absolute;
  top: -20px;
  content: "Delete";
  color: white;
  transition-duration: .3s;
  font-size: 2px;
}

.button:hover::before {
  font-size: 13px;
  opacity: 1;
  transform: translateY(30px);
  transition-duration: .3s;
}

.file-container {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: left;
}

.card {
  width: 300px;
  height: 425px;
  background: #202020;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.2s ease-in-out;
}

.img {
  height: 30%;
  position: absolute;
  transition: 0.2s ease-in-out;
  z-index: 1;
}

#bigger-img {
  height:60%
}

#arm {
  height: 15%;
}

.textBox {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  transition: 0.2s ease-in-out;
  z-index: 2;
}

.textBox > .text {
  font-weight: bold;
}

.textBox > .head {
  font-size: 20px;
  padding: 0.5em;
}

.textBox > .price {
  font-size: 17px;
}

.textBox > span {
  font-size: 12px;
  color: rgb(255, 255, 255);
  padding: 0 1.5em;
}

.card:hover > .textBox {
  opacity: 1;
}

.card:hover > .img {
  height: 55%;
  filter: blur(15px) brightness(0.7);
  animation: anim 3s infinite;
}
.card:hover > #bigger-img {
  height: 195%;
}
.card:hover > #arm {
  height: 55%;
}


@keyframes anim {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.card:hover {
  transform: scale(1.04) rotate(-1deg);
}

a {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
 }
 
 a.learn-more {
  width: 12rem;
  height: auto;
 }
 
 a.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
 }
 
 a.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
 }
 
 a.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
 }
 
 a.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
 }
 
 a.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
 }
 
 a:hover .circle {
  width: 100%;
 }
 
 a:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
 }
 
 a:hover .button-text {
  color: #fff;
 }

button {
  position: relative;
  margin-top: 10px;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
 }
 
 button.learn-more {
  width: 12rem;
  height: auto;
 }
 
 button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
 }
 
 button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
 }
 
 button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
 }
 
 button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
 }
 
 button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
 }
 
 button:hover .circle {
  width: 100%;
 }
 
 button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
 }
 
 button:hover .button-text {
  color: #fff;
 }

#scroll  {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
  margin-bottom: 10vh;
}

.mouse {
	width: 30px;
	height: 60px;
	border: 3px solid #ffffff;
	border-radius: 60px;
	position: relative;
	&::before {
		content: '';
		width: 12px;
		height: 12px;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		background-color: #fffdfd;
		border-radius: 50%;
		opacity: 1;
		animation: wheel 2s infinite;
		-webkit-animation: wheel 2s infinite;
	}
}

@keyframes wheel {
	to {
		opacity: 0;
		top: 35px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 35px;
	}
}

.scroll {
	width: 60px;
	height: 60px;
	border: 2px solid #333;
	border-radius: 50%;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 18px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #333;
  	border-bottom: 2px solid #333;
		transform: rotate(-45deg);
	}
}

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

.mern-section {
  text-align: center; /* Centers the text and logos */
  padding: 20px; /* Adds padding around the section */
  margin-top: 12vh;
}

.mern-section h2 {
  color: #ffffff; 
  font-size: 1.2rem; 
}

.logos-container {
  display: grid;
   grid-template-columns: repeat(auto-fill, minmax(250px, 2fr));
  justify-content: center;   
  place-items: center;
  /* gap: 0.5rem; */
  padding: 2rem 4rem;
  margin: 0 auto;
  max-width: 81%;
  margin-top: 1vh;
}

.logo {
  width: 75px; /* Sets a fixed width for each logo */
  height: auto; /* Maintains the aspect ratio of the logos */
}

#node {
  width: 170px; 
  height:  auto;

}

#express {
  width: 175px; 
  height:  auto;
}
#mongo {
  width: 175px; 
  height:  auto;
}